import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import { API_BASE_URL, formatCurrencyIDR, formatDate, formatDateTime } from "../../../App";
import TextBox from "../../component/TextBox";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import InvoiceExcelExport from "../../services/SalesInvoiceExportServices";

function DetailSalesInvoice(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const SONumber = searchParams.get('SONumber');
    const [detailSPPB, setDetailSPPB] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalUnitPrice, setTotalUnitPrice] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchProfitDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesinvoice.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales invoice data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSPPB(data.Data);

                    let totalQty = 0;
                    let totalPrice = 0;
                    let totalTaxes = 0;
                    let totalAmt = 0;

                    data.Data.Items.forEach(item => {
                        totalQty += parseInt(item.productQuantity);
                        totalPrice += parseInt(item.unitPrice) * parseInt(item.productQuantity);
                        totalTaxes += parseInt(item.tax);
                        totalAmt += (parseInt(item.productQuantity) * parseInt(item.unitPrice)) + parseInt(item.tax);
                    });

                    setTotalQuantity(totalQty);
                    setTotalUnitPrice(totalPrice);
                    setTotalTax(totalTaxes);
                    setTotalAmount(totalAmt);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales invoice data');
                    setShowDialog(true);
                }

            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sales invoice data', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchProfitDetail();
        }
    }, [SONumber]);

    const handleApproveAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/approvesalesinvoice.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_order_number: detailSPPB.Details[0].invoiceNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sales invoice data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sales invoice data');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error to approve sales invoice data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/rejectsalesinvoice.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_order_number: detailSPPB.Details[0].invoiceNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject sales invoice data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject sales invoice data');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error to reject sales invoice data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleExportToExcel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}sales/SalesInvoice.php?SONumber=${detailSPPB.Details[0].invoiceNumber}`, {
                method: 'GET',
            });

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `${detailSPPB.Details[0].invoiceNumber}_Sales_Invoice.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
        }
        
    };
    

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (
                    <Loader/>
                ) : (
                    detailSPPB ? (
                        <div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Sales Invoice Status</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Status"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].SO_Status_Name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created By"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].InsertBy}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created At"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDateTime(detailSPPB.Details[0].InsertDt)}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-for-grid'>
                                <div className='title-for-card'>Sales Invoice Detail</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Customer Name"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].company_name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Number"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].invoiceNumber}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Date"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDate(detailSPPB.Details[0].invoiceDate)}</div>
                                    </div>
                                </div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Ship To"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].company_name}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Number"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailSPPB.Details[0].invoiceNumber}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Invoice Date"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDate(detailSPPB.Details[0].invoiceDate)}</div>
                                    </div>
                                </div>
                                <div className="scrollable-horizontal">
                                    {detailSPPB.Items.map((item, index) => {
                                        const total = (parseInt(item.productQuantity) * parseInt(item.unitPrice)) + parseInt(item.tax);

                                        return(
                                            <div key={index}>
                                                <div className='ItemNameTemplateForm'>
                                                    <FormLabel labelName={`Item #${index + 1}`} />
                                                </div>
                                                <div className='grid-container-scrollable'>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Nama Barang"} />
                                                        <TextBox value={item.productName} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"QTY"} />
                                                        <TextBox value={item.productQuantity} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Unit Price"} />
                                                        <TextBox value={formatCurrencyIDR(item.unitPrice)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Tax"} />
                                                        <TextBox value={formatCurrencyIDR(item.tax)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"Total Amount"} />
                                                        <TextBox value={formatCurrencyIDR(total)} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"No. SO"} />
                                                        <TextBox value={item.SONumber} />
                                                    </div>
                                                    <div className="only-100-width">
                                                        <FormLabel labelName={"No. DO"} />
                                                        <TextBox value={item.DONumber} />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div>
                                        <div className='grid-container-scrollable'>
                                            <div className="only-100-width">
                                                Total
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={totalQuantity} />
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalUnitPrice)} />
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalTax)} />
                                            </div>
                                            <div className="only-100-width">
                                                <TextBox value={formatCurrencyIDR(totalAmount)} />
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>          
                                <div className='space-between-1vh'></div>    
                                <div className='grid-container-with-padding-left'>
                                    {
                                        detailSPPB.Details[0].SO_Status_Name === 'Sales Invoice Draft' && permissionAccess === 'Full access' ? (
                                            <div className="only-100-width-card-approve">
                                                <div onClick={handleApproveAction}>Approve</div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )
                                    }
                                </div>     
                                <div className='grid-container-with-padding-left'>
                                    {
                                        detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Approved' ? (
                                            <div className="only-100-width-card-approve">
                                                <div onClick={handleExportToExcel}>Export to excel</div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )
                                    }
                                </div>                                                
                            </div>
                        </div>
                    ) : (
                        <Loader/>
                    )
                )}
            </div>
        </div>
    );
}

export default DetailSalesInvoice;
