import { useState } from "react";
import { API_BASE_URL } from "../../../App";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import NotFoundPage from "../../component/maintanance/NotFound";
import UnderConstruction from "../../component/maintanance/UnderConstruction";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import DocumentActive from "../../component/menu/DocumentActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import Logout from "../../component/menu/Logout";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import SalesNonActive from "../../component/menu/SalesNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import useAuthSession from "../../services/Session";
import WarehouseExportExcel from "../../services/WarehouseExcelExportServices";
import FindProductWarehouseReport from "../../component/dialog/FindProductWarehouseReport";
import Loader from "../../component/Loader";
import SuratJalanReport from "../../component/dialog/SuratJalanReport";
import SalesInvoiceReport from "../../component/dialog/SalesInvoiceReport";
import BukuKasReport from "../../component/dialog/BukuKasReport";
import PODocs from "../../component/dialog/FindPODocx";
import SODocs from "../../component/dialog/FindSODocx";
import SPPBDocs from "../../component/dialog/FIndSPPBDocx";
import SuratProfit from "../../component/dialog/FindProfitDocx";
import ARReport from "../../component/dialog/FindARDocx";

function DocumentIndex(){
    const { username } = useAuthSession();
    const permissionAccess = localStorage.getItem('permissionAccess');
    const [period, setPeriod] = useState('');
    const [loading, setLoading] = useState(false);
    const [findReportShowDialog, setFindReportShowDialog] = useState(false);
    const [suratJalanDialog, setSuratJalanDialog] = useState(false);
    const [bukuKasDialog, setBukuKasDialog] = useState(false);
    const [poPDFDialog, setPOPDFDialog] = useState(false);
    const [soExcelDialog, setSOExcelDialog] = useState(false);
    const [sppbExcelDialog, setSPPBExcelDialog] = useState(false);
    const [salesInvoiceDialog, setSalesInvoiceDialog] = useState(false);
    const [salesProfitDialog, setSalesProfitDialog] = useState(false);
    const [ARDialog, setARDialog] = useState(false);
    const [detailPurchaseLocal, setDetailPurchaseLocal] = useState(null);
    
    const fetchWarehouseData = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}warehouse/weeklyreport.php`);
            if (!response.ok) {
                throw new Error('Failed to fetch warehouse data');
            }
            const data = await response.json();
            console.log('Fetched data:', data.data);

            // Extract and set the period from the API response
            if (data.data && data.data.length > 0) {
                const dates = Array.from(new Set(data.data.flatMap(product => product.daily_transactions.map(transaction => transaction.date)))).sort();
                const periodStart = new Date(dates[0]).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
                const periodEnd = new Date(dates[dates.length - 1]).toLocaleDateString('id-ID', { day: '2-digit', month: 'long', year: 'numeric' });
                setPeriod(`${periodStart} - ${periodEnd}`);
            }

            return data.data || [];
        } catch (error) {
            console.error('Error fetching warehouse data:', error); // Debug log
            return [];
        }
    };

    const handleExportClick = async () => {
        setLoading(true);
        try {
            const data = await fetchWarehouseData();
            await WarehouseExportExcel({ excelData: data, fileName: 'WeeklyStockReport', period });
        } catch (error) {
            console.error('Error exporting data:', error); // Debug log
        } finally {
            setLoading(false);
        }
    };

    const handleNewClickFindReportShowDialog = (event) => {
        event.preventDefault();
        setFindReportShowDialog(true);
    };

    const handleCloseFindReportShowDialog = () => {
        setFindReportShowDialog(false);
    };

    const handleCloseSuratJalanDialog = () => {
        setSuratJalanDialog(false);
    };

    const handleClickSuratJalanDialog = () => {
        setSuratJalanDialog(true);
    }

    const handleCloseBukuKasDialog = () => {
        setBukuKasDialog(false);
    };

    const handleClickBukuKasDialog = () => {
        setBukuKasDialog(true);
    };
    
    const handleClosepoPDFDialog = () => {
        setPOPDFDialog(false);
    };

    const handleClickpoPDFDialog = () => {
        setPOPDFDialog(true);
    }  

    const handleClicksoExcelDialog = ()=> {
        setSOExcelDialog(true);
    }

    const handleClosesoExcelDialog = () => {
        setSOExcelDialog(false);
    }

    const handleClickSPPBExcelDialog = ()=> {
        setSPPBExcelDialog(true);
    }

    const handleCloseSPPBExcelDialog = () => {
        setSPPBExcelDialog(false);
    }
    
    const handleCloseSalesInvoicePDFDialog = () => {
        setSalesInvoiceDialog(false);
    };

    const handleClickSalesInvoiceDialog = () => {
        setSalesInvoiceDialog(true);
    }  

    const handleCloseSalesProfitPDFDialog = () => {
        setSalesProfitDialog(false);
    };

    const handleClickSalesProfitDialog = () => {
        setSalesProfitDialog(true);
    }  

    const handleCloseARDialog = () => {
        setARDialog(false);
    };

    const handleClickARDialog = () => {
        setARDialog(true);
    }  

    return(
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                {suratJalanDialog && <SuratJalanReport onCloseDialog={handleCloseSuratJalanDialog}/>}
                {findReportShowDialog && <FindProductWarehouseReport onCloseDialog={handleCloseFindReportShowDialog} />}
                {bukuKasDialog && <BukuKasReport onCloseDialog={handleCloseBukuKasDialog} />}
                {poPDFDialog && <PODocs onCloseDialog={handleClosepoPDFDialog} />}
                {soExcelDialog && <SODocs onCloseDialog={handleClosesoExcelDialog}/>}
                {sppbExcelDialog && <SPPBDocs onCloseDialog={handleCloseSPPBExcelDialog}/>}
                {salesInvoiceDialog && <SalesInvoiceReport onCloseDialog={handleCloseSalesInvoicePDFDialog}/>}
                {salesProfitDialog && <SuratProfit onCloseDialog={handleCloseSalesProfitPDFDialog}/>}
                {ARDialog && <ARReport onCloseDialog={handleCloseARDialog}/>}
                {permissionAccess === 'Full access'  ? 
                    (
                        <div>
                            {loading ? <Loader/> : (
                                <div>
                                    <div className='grid-container-smaller'>
                                        <div className='MenuName'>Document Module</div>
                                    </div>
                                    {/* <SalesInvoiceReport/> */}
                                    <div className='grid-container'>
                                        <div className="card-for-grid-divide-two" onClick={handleClickpoPDFDialog}>
                                            <div className='settings-title'>Generate PO PDF</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat PDF purchase order dan hasil akan berbentuk file PDF</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleClicksoExcelDialog}>
                                            <div className='settings-title'>Generate Surat SO</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat PDF sales order dan hasil akan berbentuk file PDF</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleClickSPPBExcelDialog}>
                                            <div className='settings-title'>Generate Surat SPPB</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat PDF sales order dan hasil akan berbentuk file PDF</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleClickSalesProfitDialog}>
                                            <div className='settings-title'>Generate Surat Profit</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat PDF surat profit dan hasil akan berbentuk file PDF</div>
                                        </div>   
                                    </div>
                                    <div className='grid-container'>
                                        <div className="card-for-grid-divide-two" onClick={handleClickBukuKasDialog}>
                                            <div className='settings-title'>Generate Buku Kas Report</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat PDF buku kas dan hasil akan berbentuk file PDF</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleExportClick}>
                                            <div className='settings-title'>Warehouse Weekly</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat Excel laporan mingguan gudang </div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleNewClickFindReportShowDialog} >
                                            <div className='settings-title'>Product Warehouse</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat Excel laporan stock produk berdasarkan kode barang</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleClickSalesInvoiceDialog}>
                                            <div className='settings-title'>Generate Sales Invoice</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat Excel sales invoice berdasarkan nomor SO</div>
                                        </div>
                                    </div>
                                    <div className='grid-container'>
                                        <div className="card-for-grid-divide-two" onClick={handleClickSuratJalanDialog}>
                                            <div className='settings-title'>Generate Surat Jalan</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat Excel surat jalan berdasarkan nomor DO</div>
                                        </div>
                                        <div className="card-for-grid-divide-two" onClick={handleClickARDialog}>
                                            <div className='settings-title'>Generate AR</div>
                                            <div className="settings-desc-2">Klik disini untuk membuat Excel AR berdasarkan nomor DO</div>
                                        </div>
                                        <div style={{ width: "100%" }}>

                                        </div>
                                        <div style={{ width: "100%" }}>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : 
                    (<div>
                        <div className='grid-container-smaller'>
                            <div className='MenuName'>Document Module</div>
                        </div>
                        <NotFoundPage menuName={'Document'}/>
                    </div>)
                }
            </div>
        </div>
    );
};

export default DocumentIndex;
