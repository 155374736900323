import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthSession from "../../services/Session";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import { useEffect, useState } from "react";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import Loader from "../../component/Loader";
import { API_BASE_URL, formatCurrencyIDR, formatDate, formatDateTime } from "../../../App";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import TextArea from "../../component/TextArea";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SOgenerateExcel from "../../services/SOExcelServices";

function SalesOrderDetail() {
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const SONumber = searchParams.get('soNumber');
    const [detailSalesOrder, setDetailSalesOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [soDate, setSoDate] = useState(new Date());
    const [dikirimKeDate, setDikirimKeDate] = useState(new Date());
    const [dikirimTglDate, setDikirimTglDate] = useState(new Date());
    const [quantities, setQuantities] = useState([]);
    const [hargasatuan, setHargaSatuan] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchSalesDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailsalesorder.php?SONumber=${SONumber}`);

                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSalesOrder(data.Data);
                    setSoDate(new Date(data.Data.Details[0].SODate));
                    setDikirimKeDate(new Date(data.Data.Details[0].SOSendTo));
                    setDikirimTglDate(new Date(data.Data.Details[0].SOSendDate));

                    const initialQuantities = data.Data.Items.map(item => item.Quantity);
                    setQuantities(initialQuantities);

                    const initialHargaSatuan = data.Data.Items.map(item => item.HargaSatuan);
                    setHargaSatuan(initialHargaSatuan);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales order data');
                    setShowDialog(true);
                }

            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchSalesDetail();
        }
    }, [SONumber]);

    const DPPCount = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return formatCurrencyIDR(DPPResult);
    };

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCount = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailSalesOrder.Details[0].PPNPercentage / 100);
        return formatCurrencyIDR(PPNResult);
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (detailSalesOrder.Details[0].PPNPercentage / 100);
        return PPNResult;
    };

    const TotalCount = (harga, quantity) => {
        const Total = (harga * quantity) + ((harga * quantity) * (detailSalesOrder.Details[0].PPNPercentage / 100));
        return formatCurrencyIDR(Total);
    };

    const createSPPB = async (SONumber) => {
        navigate(`/salesIndex/New/newSPPB/withParams/${encodeURIComponent(SONumber)}`);
    };

    const handleInvoiceAction = async(SONumber) => {
        navigate(`/salesIndex/New/newSalesInvoice/withParams/${encodeURIComponent(SONumber)}`);
    }

    const handleProfit = async(SONumber) => {
        navigate(`/salesIndex/New/newDeliveryOrder/withParams/${encodeURIComponent(SONumber)}`);
    }

    const handleSPPB = async(SONumber) => {
        navigate(`/salesIndex/New/newProfit/withParams/${encodeURIComponent(SONumber)}`);
    }

    if (loading) {
        return <Loader />;
    }

    const handleApproveAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/approvesalesorder.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_order_number: detailSalesOrder.Details[0].SONumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sales data');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve sales data');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error to approve sales order data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/rejectsalesorder.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_order_number: detailSalesOrder.Details[0].SONumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject sales order');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject sales order');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteAction = async() => {
        setLoading(true);

        try {
            const response = await fetch(API_BASE_URL + 'sales/deletesalesorder.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_order_number: detailSalesOrder.Details[0].SONumber,
                }),
            });
    
            const result = await response.json();
            if (result.StatusCode === 200) {
                navigate('/salesIndex');
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to delete sales order : ' + result.message);
                setShowDialog(true);
            }
        } catch (error) {
            console.error('Error deleting sales order:', error);
        } finally {
            setLoading(false);
        }
    }

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailSalesOrder) {
        detailSalesOrder.Items.forEach((item, index) => {
            const DPP = DPPCountW(hargasatuan[index], quantities[index]);
            const PPN = PPNCountW(hargasatuan[index], quantities[index]);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(hargasatuan[index].replace('Rp. ', '').replace('.', '').replace(',', '.'));
        });
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleRevision = async () => {

        setLoading(true);
        try {
            const API = API_BASE_URL + 'sales/revisedsalesorder.php';

            let params = new URLSearchParams({
                sales_order_number: detailSalesOrder.Details[0].SONumber,
                sales_order_date: soDate,
                sales_order_send_to: dikirimKeDate,
                sales_order_send_date: dikirimTglDate,
                insert_by: username,
                product_length: detailSalesOrder.Items.length
            });

            detailSalesOrder.Items.forEach((item, index) => {
                params.append(`sales_order_PO_${index + 1}`, item.purchaseOrderNumber);
                params.append(`sales_order_product_name_${index + 1}`, item.ProductName);
                params.append(`sales_order_product_quantity_before_${index + 1}`, item.Quantity);
                params.append(`sales_order_hargasatuan_before_${index + 1}`, item.HargaSatuan);
                params.append(`sales_order_product_quantity_after_${index + 1}`, quantities[index]);
                params.append(`sales_order_hargasatuan_after_${index + 1}`, hargasatuan[index]);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/salesIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error) {
            // setErrorCode('400');
            // setErrorTitle('Error fetching revised sales order data:', error);
            // setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const handleHargaSatuanChange = (e, index) => {
        const value = e.target.value;
        setHargaSatuan(prevHargaSatuan => {
            const newHargaSatuan = [...prevHargaSatuan];
            newHargaSatuan[index] = value;
            return newHargaSatuan;
        })
    }

    const handleExportToExcel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}sales/SOExport.php?SONumber=${encodeURIComponent(detailSalesOrder.Details[0].SONumber)}`, {
                method: 'GET',
            });

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `${encodeURIComponent(detailSalesOrder.Details[0].SONumber)}_Sales_Order.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
        }
};

    
    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace' />
                <DashboardNonActive />
                <SalesActive />
                <PurchaseNonActive />
                <FinanceNonActive />
                <WarehouseNonActive />
                <HRNonActive />
                <AnalyticsnonActive />
                <DocumentnonActive />
                <SettingNonActive />
                <Logout />
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username} />
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                { loading ? (<Loader/>) : 
                detailSalesOrder ? (
                    <div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Sales Order Status</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Status"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSalesOrder.Details[0].SOStatus}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created By"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSalesOrder.Details[0].InsertBy}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created At"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{formatDateTime(detailSalesOrder.Details[0].InsertDt)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Sales Order Detail</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Order Number"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSalesOrder.Details[0].SONumber} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"} />
                                    <div className='space-between-1vh'></div>
                                    {detailSalesOrder.Details[0].SOStatus === 'Rejected' ? <DatePicker
                                        selected={soDate}
                                        onChange={(date) => setSoDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        className="datepicker"
                                    /> : <TextBox value={formatDate(detailSalesOrder.Details[0].SODate)} />}
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"PPN / No. PPN"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSalesOrder.Details[0].PPNType_name} />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSalesOrder.Details[0].company_name} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer Address"} />
                                    <div className='space-between-1vh'></div>
                                    <TextArea maxLines={5} value={detailSalesOrder.Details[0].company_address} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Dikirim Ke"} />
                                    <div className='space-between-1vh'></div>
                                    {detailSalesOrder.Details[0].SOStatus === 'Rejected' ? <DatePicker
                                        selected={dikirimKeDate}
                                        onChange={(date) => setDikirimKeDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        className="datepicker"
                                    /> : <TextBox value={formatDate(detailSalesOrder.Details[0].SOSendTo)} />}
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Dikirim Tgl"} />
                                    <div className='space-between-1vh'></div>
                                    {detailSalesOrder.Details[0].SOStatus === 'Rejected' ? <DatePicker
                                        selected={dikirimTglDate}
                                        onChange={(date) => setDikirimTglDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        className="datepicker"
                                    /> : <TextBox value={formatDate(detailSalesOrder.Details[0].SOSendDate)} />}
                                </div>
                                <div className="only-100-width">
                                   
                                </div>
                                <div className="only-100-width">
                                   
                                </div>
                            </div>
                            <div className="scrollable-horizontal">
                                {detailSalesOrder.Items.map((item, index) => (
                                    <div key={index}>
                                        <div className='ItemNameTemplateForm'>
                                            <FormLabel labelName={`Item #${index + 1}`} />
                                        </div>
                                        <div className='grid-container-scrollable'>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Purchase Order Number"} />
                                                <TextBox value={item.purchaseOrderNumber} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Product Name"} />
                                                <TextBox value={item.ProductName} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Quantity"} />
                                                {detailSalesOrder.Details[0].SOStatus === 'Rejected' ? <TextBox value={quantities[index] || ''} onChange={(e) => handleQuantityChange(e, index)}/> : <TextBox value={item.Quantity} />}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"SAT"} />
                                                <TextBox value={item.uomName} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Harga @"} />
                                                {detailSalesOrder.Details[0].SOStatus === 'Rejected' ? <TextBox value={hargasatuan[index] || ''} onChange={(e) => handleHargaSatuanChange(e, index)}/> : <TextBox value={item.HargaSatuan} />}
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"Total"} />
                                                <TextBox placeholder={"Insert total value"} value={TotalCount(hargasatuan[index], quantities[index])} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"DPP"} />
                                                <TextBox placeholder={"Insert DPP value"} value={DPPCount(hargasatuan[index], quantities[index])} />
                                            </div>
                                            <div className="only-100-width">
                                                <FormLabel labelName={"PPN"} />
                                                <TextBox placeholder={"Insert PPN value"} value={PPNCount(hargasatuan[index], quantities[index])} />
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className='grid-container-scrollable'>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Total"} />
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrencyIDR(totalHargaSatuan)}/>
                                    </div>
                                    <div className="only-100-width"></div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrencyIDR(totalDPP)}/>
                                    </div>
                                    <div className="only-100-width">
                                        <TextBox value={formatCurrencyIDR(totalPPN)}/>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-reject">Upload PDF</div>
                            <div className="only-100-width-card-reject">Export as PDF</div> */}
                            {
                                detailSalesOrder.Details[0].SOStatus === 'Approve' || detailSalesOrder.Details[0].SOStatus === 'Delivery Order Approved' || detailSalesOrder.Details[0].SOStatus === 'Profit Approved' || detailSalesOrder.Details[0].SOStatus === 'SPPB Approved'? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleExportToExcel()}>Export to Excel</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSalesOrder.Details[0].SOStatus === 'Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleApproveAction}>Approve</div>
                                    </div>
                                ) :  detailSalesOrder.Details[0].SOStatus === 'Approve' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => createSPPB(detailSalesOrder.Details[0].SONumber)}>Create SPPB</div>
                                    </div>
                                ) : detailSalesOrder.Details[0].SOStatus === 'Delivery Order Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleInvoiceAction(detailSalesOrder.Details[0].SONumber)}>Create Sales Invoice</div>
                                    </div>
                                ) : detailSalesOrder.Details[0].SOStatus === 'Profit Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleProfit(detailSalesOrder.Details[0].SONumber)}>Create Delivery Order</div>
                                    </div>
                                ) 
                                : detailSalesOrder.Details[0].SOStatus === 'SPPB Approved' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleSPPB(detailSalesOrder.Details[0].SONumber)}>Create Profit</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSalesOrder.Details[0].SOStatus === 'Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-reject">
                                        <div className="" onClick={handleRejectAction}>Reject</div>
                                    </div>
                                ) :  detailSalesOrder.Details[0].SOStatus === 'Rejected' ? (
                                    <div className="only-100-width-card-reject">
                                        <div className="" onClick={handleRevision}>Resubmit</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSalesOrder.Details[0].SOStatus === 'Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-reject">
                                        <div className="" onClick={handleDeleteAction}>Delete SO</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}

export default SalesOrderDetail;
