import React, { useState, useEffect } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../App';
import TextBox from '../TextBox';
import DatePicker from "react-datepicker";

const BukuKasReport = ({ onCloseDialog }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allBank, setAllBank] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    useEffect(() => {
        fetchBankAccount();
    }, []);

    const fetchBankAccount = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/allbankaccount.php`);
            const data = await response.json();

            if (data.StatusCode === 200) {
                setAllBank(data.Data);
                if (data.Data.length > 0) {
                    setSelectedBank(data.Data[0].bank_number);
                }
            } else {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch bank accounts');
                setShowDialog(true);
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to fetch bank account: ' + error.message);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const handleBankAccountChange = (event) => {
        setSelectedBank(event.target.value);
    };

    const handleClickSubmit = async () => {
        setLoading(true);
        try {
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            const response = await fetch(
                `${API_BASE_URL}finance/BukuKasdocument.php?bank_account=${selectedBank}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
                { method: 'GET' }
              );
              

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `${selectedBank}_BankReport.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
            onCloseDialog();
        }
    }

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Pembuatan Laporan Buku Kas
                        </div>
                    </div>
                    <div className='TwoGridForm'>
                        <div className='TwoGridFormContainer'>
                            <label className='TwoGridFormLabel'>Tanggal Mulai</label>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={startDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setStartDate(date)}
                                placeholderText='Select start date'
                            />
                        </div>
                        <div className='space-between-width-10'></div>
                        <div className='TwoGridFormContainer'>
                            <label className='TwoGridFormLabel'>Tanggal Akhir</label>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={endDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setEndDate(date)}
                                placeholderText='Select end date'
                            />
                        </div>
                    </div>
                    <div className='TwoGridForm'>
                        <div className='TwoGridFormContainer'>
                            <label className='TwoGridFormLabel'>Bank</label>
                            <div className='space-between-1vh'></div>
                            <select className='new-form-dropdown' value={selectedBank} onChange={handleBankAccountChange}>
                                {allBank.map((bank) => (
                                    <option key={bank.bank_number} value={bank.bank_number}>
                                        {bank.bank_number} - {bank.bank_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='space-between'/>
                    <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Tutup</div>
                            <div className='space-between-width' />
                            <div
                                className={`verification-button ${loading ? 'disabled' : ''}`}
                                onClick={loading ? null : handleClickSubmit}
                            >
                                {loading ? 'Loading...' : 'Export ke Excel'}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BukuKasReport;