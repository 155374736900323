import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../App';
import TextBox from '../TextBox';

const SPPBDocs = ({ onCloseDialog }) => {
    //intialize new variable
    const [noSO, setNoSO] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);

    //handle close click
    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    const handleSOChange = async (e)=>{
        const value = e.target.value;
        setNoSO(value);
        
        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}sales/findsonumber.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.Data);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching SO suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    }

    const handleSuggestionClick = (suggestion) => {
        setNoSO(suggestion.SONumber);
        setShowSuggestions(false);
    };

    const handleSubmitClick = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}sales/SPPBExport.php?SONumber=${noSO}`, {
                method: 'GET',
            });

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `${noSO}_Sales_SPPB.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
            onCloseDialog();
        }
    };

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className="new-form-title">Dokumen Surat SPPB</div>
                    </div>
                    <div className="TwoGridForm">
                        <div className="TwoGridFormContainer">
                            <label className="TwoGridFormLabel">Nomor Sales Order</label>
                            <div className="space-between-1vh"></div>
                            <TextBox
                                placeholder="Masukkan nomor SO"
                                value={noSO}
                                onChange={handleSOChange}
                                type="text"
                                maxLines={1}
                            />
                            {showSuggestions && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.SONumber}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="space-between" />          
                    <div className="grid-container-form-submit">
                        <div className="close-text-dialog" onClick={handleCloseClick}>
                            Tutup
                        </div>
                        <div className="space-between-width" />
                        <div
                            className={`verification-button ${loading ? 'disabled' : ''}`}
                            onClick={loading ? null : handleSubmitClick}
                        >
                            {loading ? 'Loading...' : 'Export ke Excel'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SPPBDocs;