import React, { useState } from 'react';
import { API_BASE_URL } from '../../../App';
import TextBox from '../TextBox';
import DatePicker from "react-datepicker";

const ARReport = ({ onCloseDialog }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleCloseClick = () => {
        onCloseDialog(); 
    };

    const handleClickSubmit = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}finance/ARdocument.php?start_date=${startDate.toISOString().split('T')[0]}&end_date=${endDate.toISOString().split('T')[0]}`, {
                method: 'GET',
            });

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `ARDocument.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
            onCloseDialog();
        }
    }

    return(
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className='new-form-title'>
                            Pembuatan Dokumen AR
                        </div>
                    </div>
                    <div className='TwoGridForm'>
                        <div className='TwoGridFormContainer'>
                            <label className='TwoGridFormLabel'>Tanggal Mulai</label>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={startDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setStartDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                        <div className='space-between-width-10'></div>
                        <div className='TwoGridFormContainer'>
                            <label className='TwoGridFormLabel'>Tanggal Akhir</label>
                            <div className='space-between-1vh'></div>
                            <DatePicker
                                className='date-picker'
                                selected={endDate}
                                dateFormat="dd MMMM yyyy"
                                onChange={(date) => setEndDate(date)}
                                placeholderText='Select sales date'
                            />
                        </div>
                    </div>
                    <div className='space-between'/>
                    <div className='grid-container-form-submit'>
                            <div className='close-text-dialog' onClick={handleCloseClick}>Tutup</div>
                            <div className='space-between-width' />
                            <div
                                className={`verification-button ${loading ? 'disabled' : ''}`}
                                onClick={loading ? null : handleClickSubmit}
                            >
                                {loading ? 'Loading...' : 'Export ke Excel'}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ARReport;