import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DashboardNonActive from '../../component/menu/DashboardNonActive';
import SalesNonActive from '../../component/menu/SalesNonActive';
import PurchaseActive from '../../component/menu/PurchaseActive';
import FinanceNonActive from '../../component/menu/FinanceNonActive';
import WarehouseNonActive from '../../component/menu/WarehouseNonActive';
import HRNonActive from '../../component/menu/HRNonActive';
import AnalyticsnonActive from '../../component/menu/AnalyticsNonActive';
import DocumentnonActive from '../../component/menu/DocumentNonActive';
import SettingNonActive from '../../component/menu/SettingNonActive';
import Logout from '../../component/menu/Logout';
import ProfilSearchBox from '../../component/ProfilSearchBox';
import FormLabel from '../../component/FormLabel';
import TextArea from '../../component/TextArea';
import useAuthSession from '../../services/Session';
import Loader from '../../component/Loader';
import { fetchCompanys } from '../../services/PurchaseService';
import TextBox from '../../component/TextBox';
import { API_BASE_URL, formatCurrency, formatDate, formatDatePOImport, formatDateTime } from '../../../App';
import { useNavigate } from 'react-router-dom';
import handleDownloadPDF from '../../services/PDFExportServices';
import ErrorDialog from '../../component/dialog/ErrorDialog';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DetailPurchaseImport(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const PONumber = searchParams.get('poNumber');
    const [detailPurchaseImport, setDetailPurchaseImport] = useState(null);
    const [companyData, setCompanyData] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [quantities, setQuantities] = useState([]);
    const [hargasatuan, setHargaSatuan] = useState([]);
    const [purchaseDate, setPurchaseDate] = useState(new Date());
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchPurchaseDetail = async () => {
            setLoading (true);
            try {
                const response = await fetch(`${API_BASE_URL}purchase/getdetailpurchaseimport.php?PONumber=${PONumber}`);
                // if (!response.ok) {
                //     setErrorCode('400');
                //     setErrorTitle('Error failed to fetch detail purchase import data');
                //     setShowDialog(true);
                // }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailPurchaseImport(data.Data);
                    setPurchaseDate(new Date(data.Data.Details[0].PODate));

                    const initialQuantities = data.Data.Items.map(item => item.POQuantity);
                    setQuantities(initialQuantities);
                    const initialHargaSatuan = data.Data.Items.map(item => item.POUnitPrice);
                    setHargaSatuan(initialHargaSatuan);
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail purchase import data');
                    setShowDialog(true);
                }

            } catch (error) {
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail purchase import data : ', error.message);
                // setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        const fetchCompanyData = async () => {
            setLoading(true);
            try{
                const companyData = await fetchCompanys();
                if (companyData && companyData.length > 0) { // Check if data is not empty
                    setCompanyData(companyData);
                } else {
                    setCompanyData([]);
                }
            } catch (error){
                // setErrorCode('400');
                // setErrorTitle('Error failed to fetch detail company details : ', error);
                // setShowDialog(true);
            } finally {
                setLoading(false);
            }
        }

        if (PONumber) {
            fetchPurchaseDetail();
            fetchCompanyData();
        }
    }, [PONumber]);

    const hadleApproveAction = async () => {
        setLoading(true);
        try{
            const APIupdate = API_BASE_URL + 'purchase/approvepurchase.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    purchase_order_number: detailPurchaseImport.Details[0].PONumber,
                    purchase_order_status: 'e71e4fc4-d157-11ee-8',
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve purchase import');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to approve purchase import');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/purchaseIndex');
            }
        } catch (error) {
            setErrorCode('400');
            setErrorTitle('Error failed to approve purchase import : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async () => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'purchase/rejectpurchase.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    purchase_order_number: detailPurchaseImport.Details[0].PONumber,
                    purchase_order_status: 'ee1d682c-d157-11ee-8',
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject purchase import');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to reject purchase import');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/purchaseIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to reject purchase import : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleOnDelivery = async (PONumber) => {
        navigate(`/purchaseIndex/New/withParams/receivingItems/${encodeURIComponent(PONumber)}`);
    }

    const handleExportPDF = async () => {
        setLoading(true); // Show loader
        try {
            const response = await fetch(`${API_BASE_URL}purchase/exportPDF.php?PONumber=${PONumber}`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                return;
            }
    
            // Convert the response to a Blob
            const blob = await response.blob();
    
            // Check the blob type (optional for debugging)
            console.log("Blob type:", blob.type);
    
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);
    
            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
    
            // Set the filename with .docx extension
            const fileName = `${PONumber}_Purchase_Order.docx`;
            link.download = fileName;
    
            document.body.appendChild(link);
            link.click();
    
            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error in handleSubmitClick:", error);
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleQuantityChange = (e, index) => {
        const value = e.target.value;
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = value;
            return newQuantities;
        });
    };

    const TotalCount = (harga, quantity) => {
        const Total = (harga * quantity);
        return formatCurrency(Total, detailPurchaseImport.Details[0].CurrencyName);
    };

    const handleHargaSatuanChange = (e, index) => {
        const value = e.target.value;
        setHargaSatuan(prevHargaSatuan => {
            const newHargaSatuan = [...prevHargaSatuan];
            newHargaSatuan[index] = value;
            return newHargaSatuan;
        })
    }

    const DPPCountW = (harga, quantity) => {
        const DPPResult = harga * quantity;
        return DPPResult;
    };

    const PPNCountW = (harga, quantity) => {
        const PPNResult = (harga * quantity) * (11 / 100);
        return PPNResult;
    };

    let totalDPP = 0;
    let totalPPN = 0;
    let totalHargaSatuan = 0;
    
    if (detailPurchaseImport) {
        detailPurchaseImport.Items.forEach((item, index) => {
            const DPP = DPPCountW(hargasatuan[index], quantities[index]);
            const PPN = PPNCountW(hargasatuan[index], quantities[index]);
            totalDPP += parseFloat(DPP);
            totalPPN += parseFloat(PPN);
            totalHargaSatuan += parseFloat(hargasatuan[index]);
        });
    }

    const handleResubmit = async () => {
        setLoading(true);
        try{
            const API = API_BASE_URL + 'purchase/revisiedpurchaseimport.php';

            let params = new URLSearchParams({
                purchase_order_number : detailPurchaseImport.Details[0].PONumber,
                purchase_order_date : purchaseDate,
                insert_by : username,
                product_length : detailPurchaseImport.Items.length
            });

            detailPurchaseImport.Items.forEach((item, index) => {
                params.append(`purchase_order_product_name_${index + 1}`, item.POProductName);
                params.append(`purchase_order_quantity_before_${index + 1}`, item.POQuantity);
                params.append(`purchase_order_unit_price_before_${index + 1}`, item.POUnitPrice);
                params.append(`purchase_order_quantity_after_${index + 1}`, quantities[index]);
                params.append(`purchase_order_unit_price_after_${index + 1}`, hargasatuan[index]);
            });

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/purchaseIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }

        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error fetching revised sales order data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='Menu'>
            <div className='MenuApp'>
                <div className='MenuSpace'/>
                <DashboardNonActive/>
                <SalesNonActive/>
                <PurchaseActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Purchase Module</div>
                    {/* <div className='calculator-container'>
                        <div>Exchange Calculator</div>
                        <div>Weight Calculator</div>
                    </div> */}
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? 
                    (<Loader/>) : detailPurchaseImport ? (
                    <div> 
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Purchase Status</div>
                                <div className='grid-container'>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Status"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailPurchaseImport.Details[0].POStatusName}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created By"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{detailPurchaseImport.Details[0].InsertBy}</div>
                                    </div>
                                    <div className="only-100-width">
                                        <FormLabel labelName={"Created At"} />
                                        <div className='space-between-1vh'></div>
                                        <div>{formatDateTime(detailPurchaseImport.Details[0].InsertDt)}</div>
                                    </div>
                                </div>
                            </div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Purchase Order Import</div>
                            <div className='grid-container'>
                                {/* Displaying basic details */}
                                <div className="only-100-width">
                                    <FormLabel labelName={"Order Number"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].PONumber}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Supplier Name"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].SupplierName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Date"}/>
                                    <div className='space-between-1vh'></div>
                                    {detailPurchaseImport.Details[0].POStatusName === 'Rejected' ?<DatePicker
                                            selected={purchaseDate}
                                            onChange={(date) => setPurchaseDate(date)}
                                            dateFormat="dd MMMM yyyy"
                                            className="datepicker"
                                        /> : <TextBox value={formatDate(detailPurchaseImport.Details[0].PODate)}/>}
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Attn"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].POSupplierPIC}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Shipment"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].ShipmentName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Term"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].term_name}/>
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Payment"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].PaymentName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Origin"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].OriginName}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Currency"}/>
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailPurchaseImport.Details[0].CurrencyName}/>
                                </div>
                            </div>
                            {detailPurchaseImport.Items.map((item, index) => (
                                <div key={index}>
                                    <div className='ItemNameTemplateForm'>
                                        <FormLabel labelName={`Item #${index + 1}`} />
                                    </div>
                                    <div className="grid-container">
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Product Name`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={item.POProductName} readOnly />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Quantity (Kg)`}/>
                                            <div className='space-between-1vh'></div>
                                            {detailPurchaseImport.Details[0].POStatusName === 'Rejected' ? <TextBox value={quantities[index]} onChange={(e) => handleQuantityChange(e, index)}/> : <TextBox value={item.POQuantity}/>}
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Packaging Size (Kg)`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={item.POPackagingSize}/>
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Unit Price (${detailPurchaseImport.Details[0].CurrencyName})`}/>
                                            <div className='space-between-1vh'></div>
                                            {detailPurchaseImport.Details[0].POStatusName === 'Rejected' ? <TextBox value={hargasatuan[index]} onChange={(e) => handleHargaSatuanChange(e, index)}/> : <TextBox value={item.POUnitPrice}/>}
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={`Total (${detailPurchaseImport.Details[0].CurrencyName})`}/>
                                            <div className='space-between-1vh'></div>
                                            <TextBox value={TotalCount(hargasatuan[index], quantities[index])} />
                                         </div>
                                    </div>
                                </div>
                            ))}
                            <div className='grid-container'>
                                <div className="only-100-width">

                                </div>
                                <div className="only-100-width">
                                    
                                </div>
                                <div className="only-100-width">
                                    
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Total"}/>
                                </div>
                                <div className="only-100-width">
                                    <TextBox value={formatCurrency(totalDPP, detailPurchaseImport.Details[0].CurrencyName)} readOnly />
                                </div>
                            </div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Should mention name : "}/>
                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_name : 'Loading...'}
                                    </div>

                                    <div className='company-data-purchase'>
                                        {companyData.length > 0 ? companyData[0].company_address : 'Loading...'}
                                    </div>

                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Shipping Marks"}/>
                                    <TextArea placeholder="Remarks" type="text" maxLines={5} value={detailPurchaseImport.Details[0].POShippingMarks}/>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Remarks"}/>
                                    <TextArea placeholder="Remarks" type="text" maxLines={5} value={detailPurchaseImport.Details[0].PORemarks}/>
                                </div>
                            </div>
                        </div>
                        {/* <div className='card-for-grid'>
                            <div className='title-for-card'>Document</div>
                        </div> */}
                        <div className='grid-container-with-padding-left'>
                            {/* <div className="only-100-width-card-reject" onClick={handleUploadPDF}>Upload PDF</div> */}
                            { detailPurchaseImport.Details[0].POStatusName === 'Approved' || detailPurchaseImport.Details[0].POStatusName === 'Received' || detailPurchaseImport.Details[0].POStatusName === 'Invoice' ? 
                                (
                                    <div className="only-100-width-card-reject" onClick={handleExportPDF}>Export as PDF</div>
                                ) : (
                                <div></div>
                            )}
                            {detailPurchaseImport.Details[0].POStatusName === 'Draft' && permissionAccess === 'Full access' ? (
                                <div className='only-100-width-card-approve'>
                                    <div onClick={hadleApproveAction}>Approve</div>
                                </div>
                            ) : detailPurchaseImport.Details[0].POStatusName === 'Approved' ? (
                                <div className='only-100-width-card-approve'>
                                    <div onClick={() => handleOnDelivery(detailPurchaseImport.Details[0].PONumber)}>Receive Items</div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            {detailPurchaseImport.Details[0].POStatusName === 'Draft' && permissionAccess === 'Full access' ? (
                                <div className='only-100-width-card-reject'>
                                    <div onClick={handleRejectAction}>Reject</div>
                                </div>
                            ) : detailPurchaseImport.Details[0].POStatusName === 'Rejected' ? (
                                <div className='only-100-width-card-reject'>
                                    <div onClick={handleResubmit}>Resubmit</div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                ) : (<Loader/>)}
                
            </div>
        </div>
    );
}

export default DetailPurchaseImport;