import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../../App';
import TextBox from '../TextBox';

const PODocs = ({ onCloseDialog }) => {
    const [noPO, setNoPO] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseClick = () => {
        onCloseDialog(); // Close the dialog
    };

    const handlePOChange = async (e) => {
        const value = e.target.value;
        setNoPO(value);

        if (value.length > 0) {
            try {
                const response = await fetch(`${API_BASE_URL}purchase/findponumber.php?find=${value}`);
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setSuggestions(data.Data);
                    setShowSuggestions(true);
                } else {
                    setSuggestions([]);
                    setShowSuggestions(false);
                }
            } catch (error) {
                console.error("Error fetching PO suggestions:", error);
                setSuggestions([]);
                setShowSuggestions(false);
            }
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setNoPO(suggestion.PONumber);
        setShowSuggestions(false);
    };

    const handleSubmitClick = async () => {
        setLoading(true); // Show loader
        try {
            const response = await fetch(`${API_BASE_URL}purchase/exportPDF.php?PONumber=${noPO}`, {
                method: 'GET'
            });
    
            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                return;
            }
    
            // Convert the response to a Blob
            const blob = await response.blob();
    
            // Check the blob type (optional for debugging)
            console.log("Blob type:", blob.type);
    
            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);
    
            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
    
            // Set the filename with .docx extension
            const fileName = `${noPO}_Purchase_Order.docx`;
            link.download = fileName;
    
            document.body.appendChild(link);
            link.click();
    
            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error in handleSubmitClick:", error);
        } finally {
            setLoading(false); // Hide loader
            onCloseDialog(); // Close the dialog
        }
    };
    

    return (
        <div className="dialog-backdrop">
            <div className="dialog-container">
                <div className="dialog-content">
                    <div>
                        <div className="new-form-title">Dokumen Surat PO</div>
                    </div>
                    <div className="TwoGridForm">
                        <div className="TwoGridFormContainer">
                            <label className="TwoGridFormLabel">Nomor Purchase Order</label>
                            <div className="space-between-1vh"></div>
                            <TextBox
                                placeholder="Masukkan nomor PO"
                                value={noPO}
                                onChange={handlePOChange}
                                type="text"
                                maxLines={1}
                            />
                            {showSuggestions && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                            {suggestion.PONumber}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="space-between" />
                    <div className="grid-container-form-submit">
                        <div className="close-text-dialog" onClick={handleCloseClick}>
                            Tutup
                        </div>
                        <div className="space-between-width" />
                        <div
                            className={`verification-button ${loading ? 'disabled' : ''}`}
                            onClick={loading ? null : handleSubmitClick}
                        >
                            {loading ? 'Loading...' : 'Export ke PDF'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PODocs;