import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthSession from "../../services/Session";
import { useEffect, useState } from "react";
import DashboardNonActive from "../../component/menu/DashboardNonActive";
import SalesActive from "../../component/menu/SalesActive";
import PurchaseNonActive from "../../component/menu/PurchaseNonActive";
import FinanceNonActive from "../../component/menu/FinanceNonActive";
import WarehouseNonActive from "../../component/menu/WarehouseNonActive";
import HRNonActive from "../../component/menu/HRNonActive";
import AnalyticsnonActive from "../../component/menu/AnalyticsNonActive";
import DocumentnonActive from "../../component/menu/DocumentNonActive";
import SettingNonActive from "../../component/menu/SettingNonActive";
import Logout from "../../component/menu/Logout";
import ProfilSearchBox from "../../component/ProfilSearchBox";
import Loader from "../../component/Loader";
import FormLabel from "../../component/FormLabel";
import TextBox from "../../component/TextBox";
import { API_BASE_URL, formatCurrencyIDR, formatDateTime } from "../../../App";
import ErrorDialog from "../../component/dialog/ErrorDialog";
import ProfitgenerateExcel from "../../services/ProfitExcelServices";

function DetailProfit(){
    const navigate = useNavigate();
    const { username } = useAuthSession();
    const [searchParams] = useSearchParams();
    const SONumber = searchParams.get('SONumber');
    const [detailSPPB, setDetailSPPB] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [errorTitle, setErrorTitle] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [landedCosts, setLandedCosts] = useState([]);
    const permissionAccess = localStorage.getItem('permissionAccess');

    useEffect(() => {
        const fetchProfitDetail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}sales/getdetailprofit.php?SONumber=${SONumber}`);
                if (!response.ok) {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales profit data');
                    setShowDialog(true);
                }
                const data = await response.json();
                if (data.StatusCode === 200) {
                    setDetailSPPB(data.Data);
                    setLandedCosts(data.Data.Items.map(item => item.LandedCost));
                } else {
                    setErrorCode('400');
                    setErrorTitle('Error failed to fetch detail sales profit data');
                    setShowDialog(true);
                }
            } catch (error) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail sales profit data : ', error);
                setShowDialog(true);
            } finally {
                setLoading(false);
            }
        };

        if (SONumber) {
            fetchProfitDetail();
        }
    }, [SONumber]);

    const calculateProfit = (hargaJual, landedCost) => {
        const hargaJualNum = parseFloat(hargaJual) || 0;
        const landedCostNum = parseFloat(landedCost) || 0;

        return hargaJualNum - landedCostNum;
    };

    const calculateProfitPercentage = (hargaJual, landedCost) => {
        const hargaJualNum = parseFloat(hargaJual) || 0;
        const landedCostNum = parseFloat(landedCost) || 0;

        return ((hargaJualNum - landedCostNum) / landedCostNum) * 100;
    };

    const totalValues = detailSPPB ? detailSPPB.Items.reduce((totals, item, index) => {
        const profit = calculateProfit(item.Price, landedCosts[index]);

        totals.totalQuantity += parseInt(item.Quantity, 10);
        totals.totalHargaJual += item.Price * item.Quantity;
        totals.totalLandedCost += landedCosts[index] * item.Quantity;
        totals.totalProfit += profit * item.Quantity;
        if (totals.totalLandedCost > 0) {
            totals.totalProfitPercentage = ((totals.totalProfit / totals.totalLandedCost) * 100);
        }
        return totals;
    }, { totalQuantity: 0, totalHargaJual: 0, totalLandedCost: 0, totalProfit: 0, totalProfitPercentage: 0 }) : {};

    const handleProfit = async() => {
        navigate(`/salesIndex/New/newDeliveryOrder/withParams/${encodeURIComponent(detailSPPB.Details[0].SalesNumber)}`);
    }

    const handleInvoiceAction = async(SONumber) => {
        navigate(`/salesIndex/New/newSalesInvoice/withParams/${encodeURIComponent(SONumber)}`);
    }

    const handleApproveAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/approveprofit.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_number: detailSPPB.Details[0].SalesNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail approve sales profit');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail approve sales profit');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail approve sales profit : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleRejectAction = async() => {
        setLoading(true);

        try{
            const APIupdate = API_BASE_URL + 'sales/rejectprofit.php';
            const response = await fetch(APIupdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    sales_number: detailSPPB.Details[0].SalesNumber,
                    update_by: username
                }),
            });

            if (!response.ok) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail reject sales profit');
                setShowDialog(true);
            }

            if (response.status === 500) {
                setErrorCode('400');
                setErrorTitle('Error failed to fetch detail reject sales profit');
                setShowDialog(true);
            }
            
            if(response.status === 200){
                navigate('/salesIndex');
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error failed to fetch detail reject sales profit : ', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    };

    const handleLandedCostChange = (e, index) => {
        const updatedLandedCosts = [...landedCosts];
        updatedLandedCosts[index] = e.target.value;
        setLandedCosts(updatedLandedCosts);
    }

    const handleResubmit = async () => {
        setLoading(true);
        try{
            const API = API_BASE_URL + 'sales/revisedprofit.php';

            let params = new URLSearchParams({
                sales_order_number : detailSPPB.Details[0].SalesNumber,
                insert_by : username,
                product_length : detailSPPB.Items.length
            });

            detailSPPB.Items.forEach((item, index) => {
                params.append(`sales_order_PO_${index + 1}`, item.PONumber);
                params.append(`sales_order_product_name_${index + 1}`, item.ProductName);
                params.append(`sales_order_quantity_${index + 1}`, item.Quantity);
                params.append(`sales_order_landed_before_${index + 1}`, item.LandedCost);
                params.append(`sales_order_landed_after_${index + 1}`, landedCosts[index]);
            })

            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params
            });

            if(response.status === 200){
                navigate(`/salesIndex`);
            }

            if(response.status === 500){
                setErrorCode('400');
                setErrorTitle('Error while update sales order data !!');
                setShowDialog(true);
            }
        } catch (error){
            setErrorCode('400');
            setErrorTitle('Error fetching revised sales order data:', error);
            setShowDialog(true);
        } finally {
            setLoading(false);
        }
    }

    const handleExportToExcel = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}sales/ProfitExport.php?SONumber=${encodeURIComponent(detailSPPB.Details[0].SalesNumber)}`, {
                method: 'GET',
            });

            if (!response.ok) {
                console.error("Failed to fetch the document:", response.statusText);
                setLoading(false);
                return;
            }

            // Convert the response to a Blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link element to trigger the download
            const link = document.createElement('a');
            link.href = url;

            // Set file name
            const fileName = `${encodeURIComponent(detailSPPB.Details[0].SalesNumber)}_Sales_Profit.xlsx`;
            link.download = fileName;

            // Trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the Excel file:", error);
        } finally {
            setLoading(false);
        }
    };

    return(
        <div className="Menu">
            <div className='MenuApp'>
                <div className='MenuSpace'></div>
                <DashboardNonActive/>
                <SalesActive/>
                <PurchaseNonActive/>
                <FinanceNonActive/>
                <WarehouseNonActive/>
                <HRNonActive/>
                <AnalyticsnonActive/>
                <DocumentnonActive/>
                <SettingNonActive/>
                <Logout/>
            </div>
            <div className='MainMenu'>
                <ProfilSearchBox username={username}/>
                <div className='grid-container-smaller'>
                    <div className='MenuName'>Sales Module</div>
                </div>
                {showDialog && <ErrorDialog errorCode={errorCode} errorMessage={errorTitle} onCloseDialog={handleCloseDialog} />}
                {loading ? (<Loader/>) : detailSPPB ? (
                    <div> 
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Sales Profit Status</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Status"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSPPB.Details[0].SO_Status_Name}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created By"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{detailSPPB.Details[0].InsertBy}</div>
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Created At"} />
                                    <div className='space-between-1vh'></div>
                                    <div>{formatDateTime(detailSPPB.Details[0].InsertDt)}</div>
                                </div>
                            </div>
                        </div>
                        <div className='card-for-grid'>
                            <div className='title-for-card'>Sales Profit Detail</div>
                            <div className='grid-container'>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Sales Order Number"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSPPB.Details[0].SalesNumber} />
                                </div>
                                <div className="only-100-width">
                                    <FormLabel labelName={"Customer"} />
                                    <div className='space-between-1vh'></div>
                                    <TextBox value={detailSPPB.Details[0].company_name} />
                                </div>
                                <div className="only-100-width">
                                   
                                </div>
                            </div>
                            <div className="scrollable-horizontal">
                                {detailSPPB.Items.map((item, index) => {
                                    console.log(item.Price);
                                    const profit = calculateProfit(item.Price, landedCosts[index]);
                                    const profitPercentage = calculateProfitPercentage(item.Price, landedCosts[index]);

                                    return(
                                        <div key={index}>
                                            <div className='ItemNameTemplateForm'>
                                                <FormLabel labelName={`Item #${index + 1}`} />
                                            </div>
                                            <div className='grid-container-scrollable'>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Nama Barang"} />
                                                    <TextBox value={item.ProductName} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"QTY"} />
                                                    <TextBox value={item.Quantity} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Harga Jual Satuan"} />
                                                    <TextBox value={formatCurrencyIDR(item.Price)} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Landed Cost"} />
                                                    {detailSPPB.Details[0].SO_Status_Name === 'Profit Rejected' ? (
                                                        <TextBox value={landedCosts[index]} onChange={(e) => handleLandedCostChange(e, index)} />
                                                    ) : (
                                                        <TextBox value={formatCurrencyIDR(landedCosts[index])} />
                                                    )}
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Profit"} />
                                                    <TextBox value={formatCurrencyIDR(profit)} />
                                                </div>
                                                <div className="only-100-width">
                                                    <FormLabel labelName={"Profit %"} />
                                                    <TextBox value={`${profitPercentage.toFixed(2)}%`} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div>
                                    <div className='grid-container-scrollable'>
                                        <div className="only-100-width">

                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total QTY"} />
                                            <TextBox value={totalValues.totalQuantity} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Harga Jual Satuan"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalHargaJual)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Landed Cost"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalLandedCost)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Profit"} />
                                            <TextBox value={formatCurrencyIDR(totalValues.totalProfit)} />
                                        </div>
                                        <div className="only-100-width">
                                            <FormLabel labelName={"Total Profit %"} />
                                            <TextBox value={`${totalValues.totalProfitPercentage.toFixed(2)}%`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid-container-with-padding-left'>
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'Profit Approved' || detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Approved'? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleExportToExcel}>Export to Excel</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'Profit Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={handleApproveAction}>Approve</div>
                                    </div>
                                ) :  detailSPPB.Details[0].SO_Status_Name === 'Profit Approved'? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleProfit(detailSPPB.Details[0].SPPBNumber)}>Create Delivery Order</div>
                                    </div>
                                ) :  detailSPPB.Details[0].SO_Status_Name === 'Delivery Order Approved'? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleInvoiceAction(detailSPPB.Details[0].SPPBNumber)}>Create Delivery Order</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            {
                                detailSPPB.Details[0].SO_Status_Name === 'Profit Draft' && permissionAccess === 'Full access' ? (
                                    <div className="only-100-width-card-reject">
                                        <div onClick={handleRejectAction}>Reject</div>
                                    </div>
                                ) :  detailSPPB.Details[0].SO_Status_Name === 'SPPB Draft'? (
                                    <div className="only-100-width-card-approve">
                                        <div onClick={() => handleProfit(detailSPPB.Details[0].SPPBNumber)}>Create Profit</div>
                                    </div>
                                ) :  detailSPPB.Details[0].SO_Status_Name === 'Profit Rejected'? (
                                    <div className="only-100-width-card-reject">
                                        <div onClick={handleResubmit}>Resubmit</div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <Loader/>
                )}
            </div>
        </div>
    );
}

export default DetailProfit;
